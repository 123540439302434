import React, { useEffect, useState } from 'react'
import { graphql } from 'gatsby'
import { SliceZone } from '@prismicio/react'

import Layout from '../components/Layout'
import { Seo } from '../components/Seo'
import { components } from '../slices/brands/diabolica'

//component
import DiabolicaHero from '../components/brands/diabolica/DiabolicaHero'

//preview
import { withPrismicPreview } from "gatsby-plugin-prismic-previews";

const BrandDiabolica = ({ data }) => {
    const [sliceData, setSliceData] = useState([])
    /* REFORMAT DATA FOR SLICEZONE DATA */
    const pageData = data.prismicSingleBrandPage.data

    useEffect(() => {
        /* ADD MODULES AND SECTIONS TO SLICEDATA */
        var newSliceData = data.prismicSingleBrandPage.data.body

        // Push products to sliceData array
        var products = pageData.body1
        var productsSliceData = {
            slice_type: "products",
            primary: {
                section_title: pageData.products_section_title.text,
                products: products
            }
        }

        var checkProductsDuplicateData = newSliceData.map((item) => {
            if(item.slice_type === "products") return true;
            return false;
        })
        if(!checkProductsDuplicateData.includes(true)) newSliceData.splice(0, 0, productsSliceData)
        //Eof push products to sliceData array

        setSliceData(newSliceData)

        //Scroll to the top on new page
        document.documentElement.style.scrollBehavior = 'auto'
        window.scrollTo(0,0)
        document.documentElement.style.scrollBehavior = 'smooth'
    }, [data.prismicSingleBrandPage.data.body, pageData.body1, pageData.products_section_title.text])

    return(
        <Layout currentPage="diabolica" customPage={true}>
            <Seo
              title={(pageData.meta_title)? pageData.meta_title.text : null}
              description={(pageData.meta_description)? pageData.meta_description.text : null}
            />
            <DiabolicaHero
                title={pageData.banner_title.text}
                description={pageData.banner_description.text}
                logoUrl={pageData.brand_logo.url}
                logoAlt={pageData.brand_logo.alt}
            />
            <SliceZone slices={sliceData} components={components} />
        </Layout>
    )
}


export const query = graphql`
    query BrandDiabolicaQuery {
        prismicSingleBrandPage(uid: {eq: "diabolica"}) {
            _previewable
            data {
                meta_title {
                    text
                }
                meta_description{
                    text
                }
                banner_title {
                    text
                }
                banner_description {
                    text
                    html
                }
                brand_logo {
                    url
                    alt
                }
                products_section_title {
                    text
                },
                body {
                    ... on PrismicSliceType {
                        slice_type
                    }
                    ...on PrismicSingleBrandPageDataBodyOurWineMakersNote {
                        id
                        slice_type
                        primary {
                            section_button_text {
                                text
                            }
                            section_button_link {
                                raw
                                size
                                slug
                                tags
                                target
                                type
                                uid
                                url
                                link_type
                                lang
                                isBroken
                                id
                            }
                            section_title {
                                text
                            }
                            section_description {
                                text
                                html
                            }
                        }
                    }
                }
                body1 {
                    ... on PrismicSingleBrandPageDataBody1Product {
                        id
                        items {
                            product_region
                        }
                        primary {
                          product_description {
                            text
                          }
                          product_list_image{
                            url
                            alt
                          }
                          product_link_text {
                            text
                          }
                          product_name {
                            text
                          }
                          where_to_buy_link {
                            raw
                            size
                            slug
                            tags
                            target
                            type
                            uid
                            url
                            link_type
                            lang
                            isBroken
                            id
                          }
                        }
                        slice_type
                      }
                }
            }
        }
    }
`

export default withPrismicPreview(BrandDiabolica)