import * as React from 'react'
import { useEffect, useState, useRef } from 'react'

//assets
import HeroBg from '../../../assets/images/brands/diabolica/hero-bg.png'
import HeroBgDeco from '../../../assets/images/brands/diabolica/hero-deco-black.png'

import { isElementInViewport } from '../../functions'

const DiabolicaHero = ({ title, description, logoUrl, logoAlt }) => {
    const [isInView, setIsInView] = useState(false)
    const ref = useRef(null)

     //create an event listener
     useEffect(() => {
        if(isElementInViewport(ref.current)){
            setIsInView(true)
        }
    }, [])
    return(
        <>
            <section
                className={(isInView)? 'brand-pg__hero d-flex justify-content-center align-items-center in-view' : 'brand-pg__hero d-flex justify-content-center align-items-center'}
                ref={ref}
                style={{backgroundImage: `url(${HeroBg})`}}
                >
                <div className='brand-pg__hero-bg text-center'>
                    <img src={HeroBgDeco} alt="Diabolica" />
                </div>
                <div className="brand-pg__hero-content text-center d-flex flex-column text-center justify-content-center">
                    {/* Logo */}
                    {(logoUrl)? <img src={logoUrl} alt={(logoAlt)? logoAlt : "Diabolica Logo"} width="60%" className='pb-5 slide-up' /> : null}
                    <div className='justify-self-end slide-up animation-delay-1'>
                        {/* Title */}
                        {(title)? <h2 className='mb-0'>{title}</h2> : null}
                        {/* Description */}
                        {(description)?
                        <div className='desc' dangerouslySetInnerHTML={{__html: description}}/>
                        : null}
                        {/* Button */}
                        <a href="https://www.diabolicawines.com/" target="_blank" rel="nofollow noreferrer">
                            <button className='btn btn-primary-2 mb-0'>Visit Diabolica Wines Website</button>
                        </a>
                    </div>
                </div>
            </section>
        </>
    )
}

export default DiabolicaHero